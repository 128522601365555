<template>
  <footer id="footer" class="section-footer">
    <div class="footer">
      <div class="branch-info">
        <nuxt-link :to="PAGE_URLS.HOME" class="logo">
          <BaseImg src="/assets/brand/logo.svg" alt="logo" />
        </nuxt-link>
        <BranchInfo />
      </div>
      <div class="footer-navigation">
        <div class="footer-navigation--info">
          <div v-for="item in NAVIGATIONS" :key="item.title" class="footer-section">
            <p class="section-title">
              {{ item.title }}
            </p>
            <div v-if="item.subCategories.length <= 5">
              <nuxt-link
                v-for="category in item.subCategories"
                :id="category.title"
                :key="category.title"
                class="category-name"
                :to="category.link"
                :target="category?.type === 'BLANK' ? '_blank' : '_self'"
              >
                {{ category.title }}
              </nuxt-link>
              <nuxt-link
                v-if="item.subCategories.length < 2 && $config.SHOW_NEWS !== '0'"
                class="category-name"
                :to="PAGE_URLS.NEWS"
              >
                Tin tức
              </nuxt-link>
            </div>
            <div v-else>
              <div class="row-footer">
                <div class="column">
                  <nuxt-link
                    v-for="category in item.subCategories.slice(0, 4)"
                    :id="category.title"
                    :key="category.title"
                    class="category-name"
                    :to="category.link"
                  >
                    {{ category.title }}
                  </nuxt-link>
                </div>
                <div class="column-right">
                  <nuxt-link
                    v-for="category in item.subCategories.slice(4)"
                    :id="category.title"
                    :key="category.title"
                    class="category-name"
                    :to="category.link"
                  >
                    {{ category.title }}
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-deposit">
        <div class="footer-deposit--info container-custom">
          <nuxt-link
            v-for="item in DEPOSIT_LIST"
            :id="item.title"
            :key="item.title"
            :to="item?.link || '/#'"
            class="deposit-item"
            @click="handleClickMethod(item)"
          >
            <BaseImg class="deposit-image" :src="item.image" :alt="item?.title" />
            <p class="deposit-title">{{ item.title }}</p>
          </nuxt-link>
        </div>
      </div>
      <div class="footer-reserve">
        <div class="footer-reserve--info container-custom">
          <div class="list-reserve">
            <nuxt-link
              title="DMCA.com Protection Status"
              target="_blank"
              to="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
            >
              <BaseImg src="assets/images/components/desktop/footer/dmca.svg" class="dmca" alt="copy right" />
            </nuxt-link>
            <p class="reserve-text">{{ COPY_RIGHT }}</p>
            <BaseImg
              src="assets/images/components/desktop/footer/copyright-logo.png"
              class="reserve-image"
              alt="copy right"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useModal from '~/composables/useModal'
import { useAppStore } from '~~/store/app'
import { DEPOSIT_LIST, getFooterContent, getFooterNavigation } from '@/constants/footer'
import { MODAL_TYPES } from '~/config/constant'
import { PAGE_URLS } from '~/config/page-url'
import { useContact } from '~/composables/useContact'
import BranchInfo from '~~/components/common/branch-info.vue'

const { openModalWithNavigate } = useModal()
const router = useRouter()
const { $config, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)

const { fetchBranchContact, contacts } = useContact()

onMounted(async () => {
  await fetchBranchContact()
})

const contactFooters = computed(() => {
  return contacts.value.filter((item) => !['facebook', 'fanpage'].includes(item.name.toLowerCase()))
})

const { COPY_RIGHT, SLOGAN_DESCRIPTION, VIEW_MORE } = getFooterContent()
const { NAVIGATIONS } = getFooterNavigation()

const handleClickMethod = (item: any) => {
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
  } else {
    navigateTo(item?.link)
  }
}

const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/footer/index.scss" />
